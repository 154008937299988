import React from "react";
import styled from "styled-components";

const StyledHeader = styled.div`
  font-size: 40px;
`;

function Header() {
  return <StyledHeader>Загрузка данных о скважинах</StyledHeader>;
}

export default Header;
