import React from "react";
import styled from "styled-components";
import plusSVG from "../../static/svg/plus.svg";
import plusBlackSVG from "../../static/svg/plusBlack.svg";
import crossSVG from "../../static/svg/cross.svg";

const StyledButton = styled.button<{ isFilled?: boolean; isSVG?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: ${(props) => (props.isSVG ? "50px" : "250px")};
  max-width: 350px;
  height: 50px;
  background-color: ${(props) => (props.isFilled ? "#286EF5" : "transparent")};
  border-radius: 10px;
  border: ${(props) =>
    props.isFilled
      ? "none"
      : props.isSVG
      ? "1px solid black"
      : "1px solid #2058c4"};
  color: ${(props) => (props.isFilled ? "white" : "#2058c4")};
  font-size: 24px;
  font-weight: 300;
  font-family: Inter;
  padding: 0 16px;
  box-sizing: border-box;

  cursor: pointer;
`;

const StyledIcon = styled.div<{ icon: any }>`
  width: 20px;
  height: 20px;
  background: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
`;

type Props = {
  text?: string;
  filled?: boolean;
  isSVG?: boolean;
  icon?: string;
  onClick: () => void;
  disabled?: boolean;
};

function Button({ text, filled, isSVG, icon, onClick, disabled }: Props) {
  let currIcon;

  switch (icon) {
    case "plus":
      currIcon = plusSVG;

      break;
    case "plus-black":
      currIcon = plusBlackSVG;

      break;
    case "cross":
      currIcon = crossSVG;

      break;
    default:
      break;
  }

  return (
    <StyledButton
      disabled={disabled}
      isFilled={filled}
      isSVG={isSVG}
      onClick={() => onClick()}
    >
      {icon && <StyledIcon icon={currIcon} />}

      {text && <span>{text}</span>}
    </StyledButton>
  );
}

export default Button;
