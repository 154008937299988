import { createGlobalStyle } from "styled-components";

import Inter from "./fonts/Inter.ttf";

const GlobalFonts = createGlobalStyle`

@font-face {
  font-family: 'Inter';
  src: url(${Inter}) format('truetype');
}

/* Chrome, Safari, Edge, Opera */

`;

export default GlobalFonts;
