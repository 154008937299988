import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

export interface Interval {
  low: string;
  high: string;
}

export interface AdditionalInfo {
  well_number: string;
  profile: string;
  date: string;
  column: string;
}

export interface FileInterface {
  file: File;
  intervals: Array<Interval>;
  additional: AdditionalInfo;
}

export interface Files {
  files: Array<FileInterface>;
}

const initialState: Files = {
  files: [],
};

export const filesSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addFile: (state, action: PayloadAction<FileInterface>) => {
      const file = action.payload;
      state.files.push(file);
    },
    removeFile: (state, action: PayloadAction<{ index: number }>) => {
      const index = action.payload.index;

      const files = state.files;
      files.splice(index, 1);

      state.files = files;
    },
    changeAdditionalInput: (
      state,
      action: PayloadAction<{
        index: number;
        key: keyof AdditionalInfo;
        value: string;
      }>
    ) => {
      const payload = action.payload;
      const key: keyof AdditionalInfo = payload.key;
      const file = state.files[payload.index];
      file.additional[key] = payload.value;

      state.files[payload.index] = file;
    },
    changeInterval: (
      state,
      action: PayloadAction<{
        index: number;
        key: "low" | "high";
        intervalIndex: number;
        value: string;
      }>
    ) => {
      const payload = action.payload;
      const key: keyof Interval = payload.key;
      const file = state.files[payload.index];
      file.intervals[payload.intervalIndex][key] = payload.value;

      state.files[payload.index] = file;
    },
    addInterval: (state, action: PayloadAction<{ index: number }>) => {
      const payload = action.payload;
      const file = state.files[payload.index];
      file.intervals.push({ low: "", high: "" });

      state.files[payload.index] = file;
    },
    removeInterval: (
      state,
      action: PayloadAction<{ index: number; intervalIndex: number }>
    ) => {
      const payload = action.payload;
      const file = state.files[payload.index];
      if (file.intervals.length === 1) {
        file.intervals[0] = { low: "", high: "" };
      } else {
        file.intervals.splice(payload.intervalIndex, 1);
      }

      state.files[payload.index] = file;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addFile,
  changeInterval,
  addInterval,
  removeInterval,
  removeFile,
  changeAdditionalInput,
} = filesSlice.actions;

export default filesSlice.reducer;
