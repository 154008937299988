import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  width: 228px;
  height: 45px;
  font-size: 24px;

  border-radius: 10px;
  border: 1px solid black;
  background: transparent;
  text-align: center;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

type Props = {
  index: number;
  placeholder: string;
  value: string;
  onChange: (value: string, index: number) => void;
};

function Input({ index, placeholder, value, onChange }: Props) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, index);
  };

  return (
    <StyledInput
      placeholder={placeholder}
      value={value}
      onChange={handleInputChange}
      type="number"
    />
  );
}

export default Input;
