import React from "react";
import styled from "styled-components";

import TrashSVG from "../../../static/svg/trash.svg";
import Input from "../../Input";
import Button from "../../Button";

import { AdditionalInfo, Interval } from "../../../store/slices/files";
import { useDispatch } from "react-redux";

import InputMask from "react-input-mask";

import {
  changeInterval,
  addInterval,
  removeInterval,
  removeFile,
  changeAdditionalInput,
} from "../../../store/slices/files";

const StyledHeadInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeadInput = styled.input`
  background: transparent;
  width: 90px;
  height: 45px;
  border: none;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid black;
  outline: none;
`;

const StyledInputTitle = styled.span`
  height: fit-content;
  color: rgba(0, 0, 0, 0.6);
  font-size: 24px;
  margin-right: 16px;
`;

const StyledWrapper = styled.div`
  padding: 16px;
  display: flex;
`;

const StyledFileInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  height: 65px;
  border-radius: 5px;
  width: 100%;
`;

const StyledFile = styled.div`
  display: flex;
  flex-direction: column;

  width: 951px;

  margin-bottom: 16px;

  background: rgba(212, 226, 253, 0.5);
  border-radius: 5px;
  padding: 2px;
`;

const StyledFileTitle = styled.div`
  display: flex;
  font-size: 24px;
`;
const StyledDeleteIcon = styled.div`
  background: url(${TrashSVG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-right: 6px;

  cursor: pointer;
`;

const StyledInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: auto;
`;

const StyledInput = styled.div`
  display: flex;
  gap: 8px;
`;

interface Props {
  index: number;
  name: string;
  intervals: Array<Interval>;
  additional: AdditionalInfo;
}

function File({ index, name, intervals, additional }: Props) {
  const dispatch = useDispatch();

  const onLowInputChange = (payload: string, intervalIndex: number) => {
    dispatch(
      changeInterval({ key: "low", index, intervalIndex, value: payload })
    );
  };

  const onHighInputChange = (payload: string, intervalIndex: number) => {
    dispatch(
      changeInterval({ key: "high", index, intervalIndex, value: payload })
    );
  };

  const changeAdditionalData = (key: keyof AdditionalInfo, payload: string) => {
    dispatch(changeAdditionalInput({ key, index, value: payload }));
  };

  const onAddIntervalClick = () => {
    dispatch(addInterval({ index }));
  };

  const onRemoveIntervalClick = (intervalIndex: number) => {
    dispatch(removeInterval({ index, intervalIndex }));
  };

  const onRemoveFileClick = () => {
    dispatch(removeFile({ index }));
  };

  return (
    <StyledFile>
      <StyledFileInputs>
        <StyledHeadInputWrapper>
          <StyledInputTitle>№ скв.</StyledInputTitle>
          <StyledHeadInput
            value={additional.well_number}
            onChange={(e) =>
              changeAdditionalData("well_number", e.target.value)
            }
          />
        </StyledHeadInputWrapper>
        <StyledHeadInputWrapper>
          <StyledInputTitle>Профиль</StyledInputTitle>
          <StyledHeadInput
            value={additional.profile}
            onChange={(e) => changeAdditionalData("profile", e.target.value)}
          />
        </StyledHeadInputWrapper>
        <StyledHeadInputWrapper>
          <StyledInputTitle>Дата</StyledInputTitle>
          <StyledHeadInput
            style={{ width: "150px" }}
            value={additional.date}
            onChange={(e) => changeAdditionalData("date", e.target.value)}
            as={InputMask}
            mask="99/99/9999"
          />
        </StyledHeadInputWrapper>
        <StyledHeadInputWrapper>
          <StyledInputTitle>Колонна</StyledInputTitle>
          <StyledHeadInput
            value={additional.column}
            onChange={(e) => changeAdditionalData("column", e.target.value)}
          />
        </StyledHeadInputWrapper>
      </StyledFileInputs>
      <StyledWrapper>
        <StyledFileTitle>
          <StyledDeleteIcon onClick={() => onRemoveFileClick()} />
          <span>{name}</span>
        </StyledFileTitle>
        <StyledInputsWrapper>
          {intervals.map((interval, index) => {
            return (
              <StyledInput>
                <Input
                  index={index}
                  placeholder="Верхний"
                  value={interval.low}
                  onChange={onLowInputChange}
                />
                <Input
                  index={index}
                  placeholder="Нижний"
                  value={interval.high}
                  onChange={onHighInputChange}
                />
                <Button
                  icon="cross"
                  isSVG
                  onClick={() => onRemoveIntervalClick(index)}
                />
                {index === 0 && (
                  <Button
                    disabled={intervals.length >= 4}
                    icon="plus-black"
                    isSVG
                    onClick={onAddIntervalClick}
                  />
                )}
              </StyledInput>
            );
          })}
        </StyledInputsWrapper>
      </StyledWrapper>
    </StyledFile>
  );
}

export default File;
