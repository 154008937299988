import React from "react";
import styled from "styled-components";
import Header from "./components/Header";
import Dropzone from "./components/Dropzone";
import Files from "./components/Files";
import { useSelector } from "react-redux";
import { RootState } from "./store";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 60px;
  box-sizing: border-box;

  font-family: "Inter";
`;

function App() {
  const files = useSelector((state: RootState) => state.filesReducer.files);
  const isFileDropped = Boolean(files.length);

  return (
    <StyledApp>
      <Header />
      {!isFileDropped ? <Dropzone /> : <Files />}
    </StyledApp>
  );
}

export default App;
