import React, { useCallback } from "react";
import Button from "../Button";
import styled from "styled-components";
import File from "./File";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDropzone } from "react-dropzone";

import { addFile } from "../../store/slices/files";
import { useDispatch } from "react-redux";

import { createExcel, loadFiles } from "../../api";

const StyledFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const StyledFilesButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFilesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFilesListTitle = styled.div`
  display: flex;

  padding: 0 16px;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const StyledFilesListTitleColumn = styled.div`
  font-size: 30px;

  &:last-child {
    margin: auto;
  }
`;

function Files() {
  const dispatch = useDispatch();

  const files = useSelector((state: RootState) => state.filesReducer.files);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((item: File) => {
        return dispatch(
          addFile({
            file: item,
            intervals: [{ low: "", high: "" }],
            additional: {
              well_number: "",
              column: "",
              profile: "",
              date: "",
            },
          })
        );
      });
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noDrag: true,
  });

  const onLoadFiles = async () => {
    const filesToUpload = files.map((item) => item.file);

    const additionalInfoToUpload = files.map((item) => {
      const { date, ...rest } = item.additional; // Extract date and other fields

      // Transform the date if it exists and matches "dd/MM/yyyy" format
      const formattedDate = date ? date.split("/").reverse().join("-") : date;

      return {
        ...rest,
        date: formattedDate,
      };
    });

    // Проверка и преобразование интервалов в числа
    const intervalsToUpload: any = files.reduce((acc, item) => {
      const intervals = item.intervals
        .filter(
          ({ low, high }) => !isNaN(parseFloat(low)) && !isNaN(parseFloat(high))
        ) // Проверяем, что введены числа
        .map(({ low, high }) => ({
          low: parseFloat(low),
          high: parseFloat(high),
        })); // Преобразуем строки в числа

      if (!intervals.length) return { ...acc };

      return { ...acc, [item.file.name]: intervals };
    }, {});

    try {
      const response = await loadFiles(
        filesToUpload,
        intervalsToUpload,
        additionalInfoToUpload
      );
      createExcel(response); // Генерация Excel файла на основе результата
    } catch (error) {
      console.error("Ошибка при загрузке файлов:", error);
    }
  };

  return (
    <StyledFilesWrapper>
      <StyledFilesButtons>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <Button
            icon="plus"
            text="Добавить файл"
            onClick={() => console.log("click")}
          />
        </div>

        <Button
          filled
          text="Рассчитать и скачать"
          onClick={() => onLoadFiles()}
        />
      </StyledFilesButtons>
      <StyledFilesList>
        <StyledFilesListTitle>
          <StyledFilesListTitleColumn>Файл</StyledFilesListTitleColumn>
          <StyledFilesListTitleColumn>Интервалы</StyledFilesListTitleColumn>
        </StyledFilesListTitle>
        {files.map((item, index) => (
          <File
            key={index}
            index={index}
            name={item.file.name}
            intervals={item.intervals}
            additional={item.additional}
          />
        ))}
      </StyledFilesList>
    </StyledFilesWrapper>
  );
}

export default Files;
